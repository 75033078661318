import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

import './Carousel.css';
import { base } from '../../util/apiRoutes';
import fertilovitMPlus from '../../assets/FertilovitMPlus.png';
// import fertMt from '../../assets/fertilovitMt.png';
import fertiloviteF from '../../assets/fertiloviteF.png';
import fertilovitF2Plus from '../../assets/fertilovitF2Plus.png';
import fertilovitF35 from '../../assets/fertilovitF35.png';
import fertilovitFEndo from '../../assets/fertilovitFEndo.png';
import fertilovitFPCOS from '../../assets/fertilovitFPCOS.png';
import fortiferrumP from '../../assets/fortiferrumP.jpg';
import chAlpha from '../../assets/chAlpha.jpg';
import fertilovitfthy from '../../assets/fertilovitfthy.png';
import proxeedWomen from '../../assets/proxw.png';
import proxeedPlus from '../../assets/proxp.png';
import resilovitPill from '../../assets/resilovitpill.jpg';
import ellamuselle from '../../assets/ellamuselle.png';
import { handleRedirect } from '../../util/functions';

const Carousel = () => {
  const sliderRef = useRef(null);
  const [slides, setSlides] = useState([
    // { image: fertMt, product_name: 'Fertilovit MT' },
    // { image: fertiloviteF, product_name: 'Fertilovite F' },
    // { image: fertilovitF2Plus, product_name: 'Fertilovit F2 Plus' },
    // { image: fertilovitF35, product_name: 'Fertilovit F35' },
    // { image: fertilovitFEndo, product_name: 'Fertilovit F Endo' },
    // { image: fertilovitFPCOS, product_name: 'Fertilovit F PCOS' },
    // { image: fertilovitMPlus, product_name: 'Fertilovit M Plus' },
    // { image: fertilovitfthy, product_name: 'Fertilovit F Thy' },
    // { image: resilovitPill, product_name: 'resilovit Pill' },
    // { image: proxeedWomen, product_name: 'proxeed women' },
    // { image: proxeedPlus, product_name: 'proxeed plus' },
    // { image: chAlpha, product_name: 'CH Alpha' },
    // { image: ellamuselle, product_name: 'ellamuselle' },
    // { image: fortiferrumP, product_name: 'Fortiferrum P' },
  ]);

  const getImages = async () => {
    try {
      let url = `${base}/home/carousel`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSlides(data);
      }else {
        throw new Error('Error fetching carousel');
      }
    } catch (error) {
      toast.error(error.message || 'Could not load carousel');
    }
  };

  useEffect(() => {
    if (slides.length < 1 ) {
      getImages();
      return;
    }
    const maxItems = slides.length;
    let dragging = false;
    let tracking;
    let rightTracking;

    const $slider = $('.slideshow .slider');
    const $sliderRight = $('.slideshow').clone().addClass('slideshow-right').appendTo($('.split-slideshow'));

    const rightItems = slides.map((slide) => (
      `<div class="item">
        <img src="${slide.image}" alt="${slide.product_name}" />
      </div>`
    ));
    const reverseItems = rightItems.reverse();
    $('.slider', $sliderRight).html(reverseItems.join(''));

    $slider.addClass('slideshow-left');
    const leftSlider = $('.slideshow-left').slick({
      vertical: true,
      verticalSwiping: true,
      arrows: false,
      infinite: true,
      dots: true,
      speed: 4000,
      autoplay: true,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      if (currentSlide > nextSlide && nextSlide === 0 && currentSlide === maxItems - 1) {
        $('.slideshow-right .slider').slick('slickGoTo', -1);
        $('.slideshow-text').slick('slickGoTo', maxItems);
      } else if (currentSlide < nextSlide && currentSlide === 0 && nextSlide === maxItems - 1) {
        $('.slideshow-right .slider').slick('slickGoTo', maxItems);
        $('.slideshow-text').slick('slickGoTo', -1);
      } else {
        $('.slideshow-right .slider').slick('slickGoTo', maxItems - 1 - nextSlide);
        $('.slideshow-text').slick('slickGoTo', nextSlide);
      }
    }).on('mousewheel', function (event) {
      event.preventDefault();
      if (event.deltaX > 0 || event.deltaY < 0) {
        $(this).slick('slickNext');
      } else if (event.deltaX < 0 || event.deltaY > 0) {
        $(this).slick('slickPrev');
      }
    }).on('mousedown touchstart', function () {
      dragging = true;
      tracking = $('.slick-track', $slider).css('transform');
      tracking = parseInt(tracking.split(',')[5]);
      rightTracking = $('.slideshow-right .slick-track').css('transform');
      rightTracking = parseInt(rightTracking.split(',')[5]);
    })
    .on('mousemove touchmove', function () {
      if (dragging) {
        const newTracking = $('.slideshow-left .slick-track').css('transform');
        const newTrackingValue = parseInt(newTracking.split(',')[5]);
        const diffTracking = newTrackingValue - tracking;
        $('.slideshow-right .slick-track').css({ transform: `matrix(1, 0, 0, 1, 0, ${rightTracking - diffTracking})` });
      }
    })
    .on('mouseleave touchend mouseup', function () {
      dragging = false;
    })

    $('.slideshow-right .slider').slick({
      swipe: false,
      vertical: true,
      arrows: false,
      infinite: true,
      speed: 3800,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
      initialSlide: maxItems - 1,
    });

    $('.slideshow-text').slick({
      swipe: false,
      vertical: true,
      arrows: false,
      infinite: true,
      speed: 3600,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    });

    // Cleanup function
    return () => {
      leftSlider.slick('unslick');
    };
  }, [slides]);

  const shopProduct = (e) => {
    const current = document.querySelector('.slick-active');
    const slideIndex = parseInt(current.getAttribute('data-slick-index'));
    handleRedirect(e,`${base}/products/description/name/${slides[slideIndex].product_name}`)
  };

  return (
  <div className='carousel-container'>
    <button className="shop-now-button" type="button" onClick={shopProduct}>
      Shop Now
    </button>
    <div className="split-slideshow">
      <div className="slideshow">
        <div className="slider" ref={sliderRef}>
          {slides.map((slide, index) => (
            <div className="item" key={index} onMouseUp={shopProduct}>
              <img src={slide.image} alt={slide.product_name} />
            </div>
          ))}
        </div>
      </div>
      <div className="slideshow-text">
        {slides.map((slide, index) => (
          <div className="item" key={index}>
              {/* <button className="shop-now-button" type="button" onClick={shopProduct}>
                Shop Now
              </button> */}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Carousel;
