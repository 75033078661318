import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './Checkout.css';
import Header from '../Components/Header/Header';
import { cartDelete } from '../redux/reducers/cart';
import isSignedIn from '../util/isSignedIn';

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cart);

  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [lastName, setLastName] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [email, setEmail] = useState('');
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const deleteCart = async() => {
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const response = await fetch('/carts', {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          dispatch(cartDelete())
          toast.success(`Cart has been emptied`);
        } else {
          throw new Error('Failed to delete cart');
        }
      } catch (error) {
        // Handle network errors or other issues that caused the fetch to fail
        toast.error(error.message || 'Failed to delete cart');
      }
    } else {
      // If the user is not logged in, simply update the cart in the store
      dispatch(cartDelete());
    }
  }

  const handleConfirmOrder = async (e) => {
    e.preventDefault();

    const customerInfo = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      additional_info: additionalInfo,
      email: email,
    };

    // Prepare the data to send
    const data = {
      sale: {
        customer_information: JSON.stringify(customerInfo),
        sale_items_attributes: Object.values(cartItems).map((item) => ({
          product_id: item.product.id,
          quantity: item.quantity,
          price: item.product.price,
        })),
        // delivery_location: selectedLocation.id
        delivery_location_id: selectedLocation.id
      }
    };

    try {
      // Make the API call to Rails to create the sale with line items
      const response = await fetch('/sales', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });

      if (response.ok) {
        toast.success('Checkout successful');
        deleteCart();
        if (isSignedIn) { 
          navigate(`/my_purchases`);
        }else {
          navigate(`/`);
        };
      } else {
        throw new Error('Failed to checkout');
      }
    } catch (error) {
      // Handle network error or other issues
      toast.error(error.message || 'Failed to checkout');
    }
  };

  const renderCartItems = () => {
    return Object.values(cartItems).map((item) => {
      const { id, name, price } = item.product;
      return (
        <div key={id} className="cart-item">
          <p>{name}</p>
          <p>Price: ${price}</p>
          <p>Quantity: {item.quantity}</p>
          <hr />
        </div>
      );
    });
  };

  const getTotalQuantity = () => {
    let totalQuantity = 0;
    Object.values(cartItems).forEach((item) => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  };

  const getToTalCart = () => {
    let count = 0;
    if (selectedLocation.cost) {
      console.log(selectedLocation);
      count += parseInt(selectedLocation.cost)
    };
    Object.values(cartItems).forEach((item) => {
      count +=  item.product.price * item.quantity;
    });
    return count.toFixed(2);
  };

  const getUserDetails = async() => {
    if (!isSignedIn()) return;
    try {
      // Make the API call to Rails to add the product to the cart
      const response = await fetch('/my_details', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      // Check if the response status is within the 2xx range (success range)
      if (response.ok) {
        const user = await response.json()
        setFirstName(user.firstName || '');
        setLastName(user.last_name || '');
        setEmail(user.email || '');
        setPhone(user.phone_number || '');
      } else {
        throw new Error('No data found for user');
      }
    } catch (error) {
    }
  }

  const getDeliveryLocations = async() => {
    try {
      // Make the API call to Rails to add the product to the cart
      const response = await fetch('/locations/deliveries', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      // Check if the response status is within the 2xx range (success range)
      if (response.ok) {
        const deliveryLocations = await response.json();
        setDeliveryLocations(deliveryLocations);
      } else {
        throw new Error('No delivery locations found');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserDetails();
    getDeliveryLocations();
  }, []);

  return (
    <>
      <Header />
      <div className="checkout-page container-main">
        <div className="user-data-section">
          <h3>Please Fill Out Your Details</h3>
          <form onSubmit={handleConfirmOrder}>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="location">Location</label>
              <textarea
                type="text"
                id="location"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              ></textarea>
            </div> */}
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <select
                id="location"
                name="location"
                value={selectedLocation.name}
                onChange={(e) => setSelectedLocation({id:e.target.options[e.target.selectedIndex].getAttribute('data-id'), name:e.target.value, cost:e.target.options[e.target.selectedIndex].getAttribute('data-cost')})}
                required
              >
                <option value="">Select Location</option>
                {deliveryLocations.map((location) => (
                  <option key={location.id} value={location.name} data-cost={location.cost} data-id={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="additional-info">Additional Information</label>
              <textarea
                id="additional-info"
                name="additional-info"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              ></textarea>
            </div>          
            <button type="submit" className="confirm-order-btn">
              Confirm Order
            </button>
          </form>
        </div>
        <h4 className="confirmation-message">
          An agent will call you to confirm the order and arrange delivery.
        </h4>
        <div className="total-cost-section">
          <p className="total-quantity">Total Quantity: {getTotalQuantity()}</p>
          <p className="total-quantity">Delivery: {selectedLocation.cost||0}</p>
          <p className="total-quantity">Total Price: ₵{getToTalCart()}</p>
        </div>
        <div className="cart-section">
          {renderCartItems()}
        </div>
      </div>
    </> 
  );
};

export default CheckoutPage;
