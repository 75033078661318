import React from 'react';
import Cart from '../Components/Cart/Cart';
import Header from '../Components/Header/Header';

const CartPage = () => {

  return (
    <div className="">
      <Header />
      <Cart page="full" />
    </div>
  )
};

export default CartPage;
