import React from 'react';

import './FeaturedBrands.css';
import SliderCustom from '../SliderCustom/SliderCustom';
import alf from '../../assets/alf.jpg'
import bio from '../../assets/bio.jpg'
import pls from '../../assets/pls.jpg'
import gelita from '../../assets/gelita.jpg'


const FeaturedBrands = () => {
  const brands = [
    {image: alf, logo:""},
    {image: bio, logo:""},
    {image: pls, logo:""},
    {image: gelita, logo:""},
  ]

  const displayBrandCards = () => {
    return brands.map((brand) => (
      <div key={brand.name} className="slider-item-fb">
        <img className="slider-fb-image" src={brand.image} alt={`${brand.name}`} />
        {/* <div className="slider-fb-logo-container">
          <Logo />
        </div> */}
      </div>
    ));
  };

  return (
    <SliderCustom
      title={"Featured Brands"}
      background={false}
    >
      {displayBrandCards()}
    </SliderCustom>
  );
};

export default FeaturedBrands;
