import React from 'react';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

const ProductDescription = ({text}) => {

  const sanitizedHtml = sanitizeHtml(text);
    return (
      <>
         {parse(sanitizedHtml)}
      </>
    )
};

export default ProductDescription;