import React, { useState } from 'react';
import { Breakpoint } from 'react-socks';
import ProfileIcon from '../../assets/profile_icon.svg';
import './Profile.css';
import { base } from '../../util/apiRoutes';
import isSignedIn from '../../util/isSignedIn';
import isAdmin from '../../util/isAdmin';
import { handleRedirect } from '../../util/functions';

const Profile =() => {
  const [showLinks, setShowLinks] = useState(false);

  const handleShow = () => {
    setShowLinks((prev) => !prev);
  }

  const profileIconSection = () => {
    return(
      <div
        className="profile-icon-button"
      >
        <button
            onClick={handleShow}
            className="btn-colorless"
        >
          <img
            className="icon" 
            src={ProfileIcon} 
            alt="profile" 
          />
        </button>
        <div className={`${showLinks ? "profile-links-container-show" : "profile-links-container-hide" }`}>
          {
            isAdmin &&
            <>
              <a onClick={(e) => handleRedirect(e,`${base}/products/new`)} href={`${base}/products/new`}> Admin </a>
              <a onClick={(e) => handleRedirect(e,`${base}/admin/sales`)} href={`${base}/admin/sales`}> Sales </a>
              <a onClick={(e) => handleRedirect(e,`${base}/delivery_locations`)} href={`${base}/delivery_locations`}> Delivery </a>

            </>
          }
        
          <a onClick={(e) => handleRedirect(e,`${base}/my_purchases`)} href={`${base}/my_purchases`}> Orders </a>
          {/* <a onClick={(e) => handleRedirect(e,`${base}/user/profile`)} href={`${base}/user/profile`}> PROFILE </a> */}
          <a onClick={(e) => handleRedirect(e,`${base}/logout`)} href={`${base}/logout`}> SIGN OUT </a>
        </div>
      </div>
    )
  }

  return (
    <div className='container-profile'>
      <Breakpoint customQuery="(max-width: 768px)">
        {
          isSignedIn() &&
          profileIconSection()
        }
        {
          !isSignedIn() &&
          <div className="login">
            <a href={`${base}/users/sign_in`}>
              LOG IN 
            </a>
          </div>
        }
      </Breakpoint>
      <Breakpoint customQuery="(min-width: 768px)">
        {
          isSignedIn() &&
          profileIconSection()
        }
        {
          !isSignedIn() &&
          <div className="login">
            <a href={`${base}/users/sign_in`}>
              SIGN IN 
            </a>
            <p>|</p>
            <a href={`${base}/users/sign_up`}>
              SIGN UP
            </a>
          </div>
        }
      </Breakpoint>
    </div>
  );
}

export default Profile;
