import "@hotwired/turbo-rails"
import "./controllers"

import React from 'react';
import ReactDOM from 'react-dom';

import App from "./app";

ReactDOM.render(
  <App/>,
  document.getElementById('root'),
);