import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {cartUpdateQuantity, cartRemoved} from '../../redux/reducers/cart'
// import DisplayText from '../PDP/DisplayText';
// import DisplaySwatch from '../PDP/DisplaySwatch';
import NoImage from '../../assets/firstAid.jpg';
import isSignedIn from '../../util/isSignedIn';

const CartProduct = ({ product,cartQuantity }) => {
  const dispatch = useDispatch();
  // const [imageSourceNumber, setImageSourceNumber] = useState(0);

  const getTotalPrice = () => {
    if (product.quantity < 1) return 0;
    const total = product.price * cartQuantity;
    return total.toFixed(2);
  };

  const addQuantity = async () => {
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const response = await fetch('/carts', {
          method: 'POST',
          body: JSON.stringify({
            product_id: product.id,
            quantity: cartQuantity + 1,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          toast.success(`Updated quantity of ${product.name}`);
          dispatch(cartUpdateQuantity({product, quantity: 1}));
        } else {
          throw new Error('Error updating cart');
        }
      } catch (error) {
        toast.error(error.message || 'Failed to add to cart');
      }
    } else {
      // If the user is not logged in, simply update the cart in the store
      dispatch(cartUpdateQuantity({product, quantity: 1}));
      toast.success(`Updated quantity of ${product.name}`);
    }
  };

  const reduceCartQuantity = async () => {
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const response = await fetch('/carts', {
          method: 'POST',
          body: JSON.stringify({
            product_id: product.id,
            quantity: (cartQuantity -1),
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          toast.success(`Updated quantity of ${product.name}`);
          dispatch(cartUpdateQuantity({product, quantity: -1}));
        } else {
          throw new Error('Error updating cart');
        }
      } catch (error) {
        toast.error(error.message || 'Failed to add to cart');
      }
    } else {
      // If the user is not logged in, simply update the cart in the store
      dispatch(cartUpdateQuantity({product, quantity: -1}));
      toast.success(`Updated quantity of ${product.name}`);
    }
  };

  const removeCartItem = async() => {
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const response = await fetch('/carts/item', {
          method: 'DELETE',
          body: JSON.stringify({
            product_id: product.id,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          toast.success(`Removed ${product.name} from cart`);
        }
      } catch (error) {
        toast.error(error.message ||`Failed to remove ${product.name} from cart`);
      } finally {
        dispatch(cartRemoved(product.id));
        toast.success(`Removed ${product.name} from cart`);
      }
    }else {
      dispatch(cartRemoved(product.id));
      toast.success(`Removed ${product.name} from cart`);
    }
  }
  
  const reduceQuantity = () => {
    if (cartQuantity < 2) {
      removeCartItem();
    } else {
      reduceCartQuantity();
    }
  };
  // const changeImage = (num) => {
  //   const images = productDataState.gallery;
  //   let nextImageNumber = imageSourceNumber + num;
  //   if (nextImageNumber >= images.length) {
  //     setImageSourceNumber(0);
  //   } else if (nextImageNumber < 0) {
  //     setImageSourceNumber(images.length - 1);
  //   } else {
  //     setImageSourceNumber(nextImageNumber);
  //   }
  // };

  const displayProduct = () => {
    const { name, image, quantity } = product;
    const imageSource = image || NoImage;
    // const selectedAttributes = attributes;

    return (
      <div className="cart-product-card">
        <div className="section1">
          <p className="name">{name}</p>
          <p className={`price ${quantity < 1 && 'strikeThroughText'}`}>
            <span className="symbol">₵</span>
            <span className="amount">{getTotalPrice()}</span>
          </p>
          {/* <div className="attributes d-flex-col">
            {attributes.map((attribute) => {
              if (attribute.type === "text") {
                return (
                  <DisplayText
                    key={attribute.name}
                    attribute={attribute}
                    selectedAttributes={selectedAttributes}
                    addAttribute={addAttribute}
                    cartPage={true}
                  />
                );
              } else if (attribute.type === "swatch") {
                return (
                  <DisplaySwatch
                    key={attribute.name}
                    attribute={attribute}
                    selectedAttributes={selectedAttributes}
                    addAttribute={addAttribute}
                    cartPage={true}
                  />
                );
              } else {
                return "";
              }
            })}
          </div> */}
        </div>
        <div className="section2">
          <div className="qty d-flex-col">
            <button type="button" onClick={addQuantity}>
              +
            </button>
            <p className="text">{cartQuantity}</p>
            <button type="button" onClick={reduceQuantity}>
              –
            </button>
          </div>
          <div className={`${quantity < 1 && "fade-content"} product-image`}>
            {quantity < 1 && (
              <div className="product-outOfStock">
                <p>out of stock</p>
              </div>
            )}
            <img src={imageSource} alt={name} loading="lazy" />
            {/* {gallery.length > 1 && (
              <div className="changeImageBtns">
                <button type="button" onClick={() => changeImage(-1)}>
                  {"<"}
                </button>
                <button type="button" onClick={() => changeImage(1)}>
                  {">"}
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {displayProduct()}
      <hr />
    </>
  );
};

export default CartProduct;
