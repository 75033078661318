import { createSlice } from '@reduxjs/toolkit';

const CART_STORAGE_KEY = 'cart';

const initialState = {
  cart: JSON.parse(localStorage.getItem(CART_STORAGE_KEY)) || {},
  status: '',
  error: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    cartAdded(state, action) {
      const { id } = action.payload.product;
      state.cart = { ...state.cart, [id]: action.payload };
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cart));
    },
    cartUpdateQuantity(state, action) {
      const { id } = action.payload.product;
      const quantity = state.cart[id].quantity + action.payload.quantity
      const product = state.cart[id].product
      state.cart = { ...state.cart, [id]: {product, quantity} };
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cart));
    },
    cartRemoved(state, action) {
      const id = action.payload;
      delete state.cart[id];
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cart));
    },
    cartDelete(state) {
      state.cart = {}
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cart));
    }
  },
});

export const { cartAdded, cartUpdateQuantity, cartRemoved, cartDelete } = cartSlice.actions;

export default cartSlice.reducer;
