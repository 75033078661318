import React from 'react';
import './Contact.css';
import Header from '../Components/Header/Header';

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="container-main">
        <div className="contact-section">
          <h2 className="title">LOCATE US</h2>
          <div className="map-container">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.4200684568114!2d-0.06620425042110936!3d5.652212534227629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf81dce8d2d123%3A0x4e8cf6fcaddcd3b8!2sCapa+Chemists+Ltd!5e0!3m2!1sen!2sgh!4v1547137392693"
              width="100%"
              height="450"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="info-container">
            <div className="opening-hours">
              <h3>Opening Hours</h3>
              <p>Monday - Friday: 8.00 AM - 5.00 PM</p>
            </div>
            <div className="call-us">
              <h3>Call us</h3>
              <p>(233)-24-434-4223</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
