import React from 'react';

const Item = ({icon, name, stat}) => {

  return (
    <li className="stats-banner-item">
      <div className='icon'>
        {icon}
      </div>
      <div className="details">
        <h6 className="stat">
          {stat}
        </h6>
        <p className="name">
          {name}
        </p>
      </div>
    </li>
  );
};

export default Item;
