import React from 'react';
import ReactWhatsapp from 'react-whatsapp';
import { IoLogoWhatsapp } from 'react-icons/io';

import './Whatsapp.css'

const Whatsapp = () => (
  <div className="whatsapp">
    <div className="button-aesthetics">
      <p>
        Hello, chat with us
      </p>
      <div className="icon-container-whatsapp">
        <IoLogoWhatsapp />
      </div>
    </div>
    <ReactWhatsapp number="233-53-103-4233" message="Hello Capachemist" />
  </div>
);

export default Whatsapp;