import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../../assets/logo.png'

function Logo() {
  return (
    <Link
      to={`/`}
    >
      <img src={Image} alt="logo" />
    </Link>
  );
};

export default Logo;