import React from 'react';
import CategoryLinkAdmmin from './CategoryLink';
import "./Nav.css"
import { base } from '../../util/apiRoutes';

const NavLinks = () => {

  const pages = [
    {name:'Products', url:`${base}/admin`},
    {name:'Add Product', url:`${base}/admin/add`},
    {name:'Add Admin', url:`${base}/admin/products/add`},
    {name:'Edit Category', url:`${base}/admin/categories`},
  ]

  const displayNavLinks = () => {
    return pages.map(page => (
      <CategoryLinkAdmmin
        key={page}
        page={page}
      />
    ));
  };

  return (
    <nav>
      {
        <ul className="navLinks d-flex">
          {displayNavLinks()}
        </ul>
      }
    </nav>
  );
};

export default NavLinks;
