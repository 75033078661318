import React from 'react';

import './Header.css'
import Banner from '../Banner/Banner';
import NavLinksAdmin from '../Navlink/NavLinksAdmin';
import SearchProfile from '../SearchProfile/SearchProfile';
import Logo from '../Logo/Logo'

function HeaderAdmin() {
  return (
    <>
      <Banner />
      <div className="header-main">
        <div className='header-logo'>
            <Logo />
        </div>
        <div className="header-other">
          <SearchProfile />
          <NavLinksAdmin />
        </div>
      </div>
    </>
  );
}

export default HeaderAdmin;
