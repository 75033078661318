import React, {useEffect, useState} from 'react';
import { Breakpoint } from 'react-socks';
import { useParams } from 'react-router-dom';

import { NavLink, Link } from 'react-router-dom';
import './CategorySelect.css';
import { base } from '../../util/apiRoutes';

const CategorySelect = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([])
  const { category:cat } = useParams();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const getCategories = async() => {
    const response = await fetch(`/categories`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    });
    const data = await response.json();
    setCategories(data);
  }

  useEffect(() => {
    getCategories();
  }, []);

  const isActive = (name) => {
    let active = name === cat || false;
    if (!cat && name === 'home') active = true;
    return active
  }


  return (
    <div>
      <Breakpoint customQuery="(max-width: 768px)">
        <div className='container-hamburger'>
          <div className="hamburger-icon" onClick={toggleMenu}>
            <div className={`bar ${isMenuOpen ? 'open' : ''}`} />
            <div className={`bar ${isMenuOpen ? 'open' : ''}`} />
            <div className={`bar ${isMenuOpen ? 'open' : ''}`} />
          </div>
          {isMenuOpen && (
            <div className="category-menu">
              <div className="overlay" onClick={toggleMenu} />
              <div className="menu-content">
                <ul>
                  <li className={`${isActive('home') && 'active-link'} nav-link`} onClick={toggleMenu}>
                    <Link
                      to={`${base}`}
                    >
                      HOME
                    </Link>
                  </li>
                  {categories.map((category)=> 
                    <li className={`${isActive(category.name) && 'active-link'} nav-link`} onClick={toggleMenu}>
                      <Link
                        to={`${base}/products/category/${category.name}/1/return`}
                      >
                        {category.name}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
        <br/>
      </Breakpoint>
    </div>
  );
};

export default CategorySelect;
