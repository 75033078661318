import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { cartAdded } from '../../redux/reducers/cart';
import './description.css';
// import DisplayText from './DisplayText';
// import DisplaySwatch from './DisplaySwatch';
import ProductDescription from './ProductDescription';
import NoImage from '../../assets/firstAid.jpg';
import Header from '../Header/Header';
import { base } from '../../util/apiRoutes';

import isAdmin from '../../util/isAdmin';
import isSignedIn from '../../util/isSignedIn';
import EditProduct from './EditProduct';
import { handleRedirect } from '../../util/functions';

const Description = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const { id, name } = useParams();
  const [product, setProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [isLoading, setIsLoading] = useState(true);

  const getProduct = async (e) => {
    try {
      let url;
      if (id) {
        url = `${base}/products/${id}`;
      }
      else if (name) {
        url = `${base}/find_product_name/${name}`;
      }else {
        toast.error(error.message || 'Could not find product');
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setProduct(data);
      }else {
        throw new Error('Error fetching product');
      }
    } catch (error) {
      toast.error(error.message || 'Could not find product');
      navigate(`/`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    getProduct();
  }, [id]);

  const addToCart = async () => {
    console.log("add to cart")
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const url = `${base}/carts`
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            product_id: product.id,
            quantity: 1,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          dispatch(cartAdded({ product, quantity: 1 }));
          toast.success(`Added ${product.name} to cart`);
        } else {
          throw new Error('Failed to create doctor');
        }
      } catch (error) {
        toast.error(error.message || 'Failed to add to cart');
      }
    } else {
      dispatch(cartAdded({ product, quantity: 1 }));
      toast.success(`Added ${product.name} to cart`);
    }
  };

  const editSave = () => {
    setEditMode(false);
    document.documentElement.scrollTop = 0;
  }

  const displayDescription = () => {
    if (isLoading) {
      return (
        <div>
          Loading...
        </div>
      )
    }

    const deleteProduct = async () => {
      const shouldDelete = window.confirm("Are you sure you want to delete this product?");
    
      if (shouldDelete) {
        const response = await fetch(`/products/${id}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });

        if (response.ok) {
          toast.success(`Product Deleted`);
        } else {
          toast.error(error || `Error deleting ${product.name}`);
        }
      }
    };

    return (
      <div className="product-description">
        {product && (
          <>
            {editMode ? ( // Show editable fields in edit mode
              <EditProduct product={product} onSave={editSave} />
            ) : (
              <>
                {/* Show product details in read-only mode */}
                <div className="product-thumbnails">
                  {/* {product.gallery.map((imageSource) => (
                <button
                  key={imageSource}
                  className="btn-colorless thumbnail"
                  type="button"
                  onClick={() => changeSelectedImage(imageSource)}
                >
                  <img src={imageSource} alt={product.name} loading="lazy" />
                </button>
              ))} */}
                </div>
                <div className="product-image">
                  <img src={product.image || NoImage} alt={product.name} loading="lazy" />
                </div>
                <div className="product-details">
                  <p className="product-name">{product.name}</p>
                  <p className="product-price">
                    <span className="text">PRICE:</span>
                    <span className="amount d-flex">
                      <span>₵</span>
                      <span>{product.price}</span>
                    </span>
                  </p>

                  {product.quantity > 0 ? (
                    <button type="button" className="add-toCart-btn" onClick={addToCart}>
                      ADD TO CART
                    </button>
                  ) : (
                    <button type="button" className="disabled-btn" disabled>
                      OUT OF STOCK
                    </button>
                  )}

                  <div className="product-information">
                    <ProductDescription text={product.description}/>
                  </div>

                  <div className="admin-edit-buttons">
                    {/* Show the edit button only for admins */}
                    {isAdmin() && (
                      <button className="can-edit-button" type="button" onClick={() => setEditMode(true)}>
                        Edit
                      </button>
                    )}
                    {isAdmin() && (
                      <button className="delete-product-button" type="button" onClick={deleteProduct}>
                        Delete
                      </button>
                    )}

                  </div>

                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="product-description d-flex">{displayDescription()}</div>
    </>
  );
};

export default Description;
