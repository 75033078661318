import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderCustom.css';

const SliderCustom = ({title, background, showHowMany , children}) => {
  const sliderRef = useRef(null);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: showHowMany || 4,
    slidesToScroll: 1,
    dots: false,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  }

  const goToPrevious = () => {
    sliderRef.current.slickPrev();
  }

  return (
    <div className={`slider-container ${background && "slider-container-background"}`}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      <div className="other-section">
        <h3 className="section-title">{title}</h3>
        <div className="control-buttons">
          <button className="control-button" onClick={goToPrevious}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
          <button className="control-button" onClick={goToNext}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderCustom;
