import React from 'react';
import { AiTwotoneHome } from 'react-icons/ai';
import { GiMedicinePills } from 'react-icons/gi';
import { GrMapLocation } from 'react-icons/gr';
import { TbTruckDelivery } from 'react-icons/tb';

import './ServiceStats.css'
import Item from './Item';

const ServiceStats = () => {
  const list = [
    {icon: <AiTwotoneHome /> , stat: '100 +', name: 'Families Served'},
    {icon: <TbTruckDelivery />, stat: '1.6K +', name: 'Orders Delivered'},
    {icon: <GrMapLocation />, stat: '500 +', name: 'Areas Served'},
    {icon: <GiMedicinePills />, stat: '14K +', name: 'Medicines Available'},
  ]

  return (
    <ul className='stats-banner'>
      {
        list.map((i) => (
          <Item 
            icon={i.icon}
            stat={i.stat}
            name={i.name}
          />
        )
      )}
    </ul>
  );
};

export default ServiceStats;
