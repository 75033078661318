import React, { useEffect, useState } from 'react';

import Header from '../Components/Header/Header';
import { base } from '../util/apiRoutes';
import './MyPurchases.css';
import NoImage from '../assets/firstAid.jpg';
import { Link } from 'react-router-dom';

function MyPurchases() {
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading ] = useState(true);

  const fetchSales  = async() => {
    setIsLoading(true);
    try {
      let url = `${base}/my_sales/items`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSales(data);
      }else {
        throw new Error('Error fetching purchases');
      }
    }catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchSales();
  }, []);

  if (sales.length < 1) {
    return (
      <>
        <Header />
        <div className="no-purchases">
          {
            isLoading ?
              <p>Loading...</p>
            :
              <h3>No Purchases</h3>
          }
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="sales-list">
        <h2 className="page-heading">Orders</h2>
        <ul>
          {sales.map(sale => (
            <li key={sale.id} className="sale-item">
               {
                sale.status === 'completed' &&
                <p className="item-has-been-delivered">Delivered</p>
              }

              <ul className="item-list">
                {sale.sale_items.map(item => (
                  <>
                    <li key={item.id} className="item">
                      <img className="order-image" src={`${item.product.image || NoImage}`} alt={`${item.product.name}`} />
                      <Link
                       to={`${base}/products/description/${item.product.id}`}
                      >
                        <p className="product-name">{item.product.name}</p>
                      </Link>
                      <p>{item.quantity} X {item.price}</p>
                      <p className="item-total">Total: ₵{(item.price * item.quantity).toFixed(2)}</p>
                      {/* <a className="view-link" href={`${base}/products/description/${item.product.id}`}>View</a> */}
                    
                    </li>
                    <hr className="divider" />
                  </>
                ))}
                <p>Delivery: ₵{sale.delivery_location ? sale.delivery_location.cost : 0}</p>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default MyPurchases;
