import React, { useState } from 'react';
import HeaderAdmin from '../Components/Header/HeaderAdmin';

function AddTag() {
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null);

  const addCategory = async () => {
    try {
      const response = await fetch(``);
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        console.error('Error:', response.status);
        // Handle error case (e.g., show error message)
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error case (e.g., show error message)
    }
  };

  return (
    <>
      <HeaderAdmin />
      <div>
        <h2>Category</h2>
        <div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Add New Category"
          />
          <button onClick={addCategory}>Search</button>
        </div>
        <div>
          <p>
            Category
          </p>
          <button >Delete</button>
          <button> Save Change</button>
        </div>
      </div>
    </>
  );
}

export default AddTag;
