import React, { useState, useEffect } from 'react';
import './Lifestyle.css';
import ProductCard from '../PLP/ProductCard';
import SliderCustom from '../SliderCustom/SliderCustom';

const Lifestyle = () => {
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const response = await fetch(`/products`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
      }else {
        throw new Error('Error fetching products');
      }
    } catch (error) {
      setProducts([]);
    }
  };
  
  useEffect(() => {
    getProducts();
  }, []);

  const displayProducts = () => {
    return products.map((product) => (
      <div key={product.id} className="slider-item-lifestyle">
        <ProductCard product={product} />
      </div>
    ));
  };

  return (
    <SliderCustom
      title={"Our Products"}
      background={true}
    >
      {displayProducts()}
    </SliderCustom>
  );
};

export default Lifestyle;
