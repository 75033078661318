import React from 'react';

import './Reviews.css';
import SliderCustom from '../SliderCustom/SliderCustom';
import SaraImage from '../../assets/sara.jpg'
import SammyImage from '../../assets/sammy.jpg'
import BerniceImage from '../../assets/bernice.jpg'
import Item from './Item';

const Reviews = () => {
  const rev = [
    {stars: 5, description:"I had been on the pill for ten years when I started to lose interest in sex.", userImage:BerniceImage, userName: 'Bernice, 48', date:'08/06/2023'},
    {stars: 5, description:"After 8 weeks taking Proxerex my interest in sex returned and getting an erection no longer a problem. ", userImage:SammyImage, userName: 'Sammy', date:'08/06/2023'},
    {stars: 5, description:"Having been trying for 3 years for our second baby, we had almost given up hope. Gave this a try for 1 month and fell pregnant", userImage:SaraImage, userName: 'Sara', date:'08/06/2023'},
  ]

  const displayBrandCards = () => {
    return rev.map((item) => (
      <Item 
        key={item.userName}
        userImage={item.userImage}
        userName={item.userName}
        description={item.description}
        stars={item.stars}
        date={item.date}
      />
    ));
  };

  return (
    <SliderCustom
      title={"What our customers have to say"}
      background={true}
      showHowMany={2}
    >
      {displayBrandCards()}
    </SliderCustom>
  );
};

export default Reviews;
