import React from 'react';
import './About.css';
import Header from '../Components/Header/Header';

const About = () => {
  const data = [
    { title: 'Our History', description: 'CAPA Chemists Ltd is a limited liability company established and backed by the registrar general of companies in Ghana in 2009 is considered as one of the leading supplier of fertility products in Ghana with two (2) offices in Accra including the head office. The company also has operations in the Ashanti, Brong Ahafo, Upper East and Upper West Region. We envision being a leader in the pharmaceutical industry by providing enhanced products, relationship and profitability.' },
    { title: 'Our Vision & Mission', description: 'Our Vision is to provide quality products that exceeds the expectations of our esteemed customers with a mission to build long term relationships with our customers and clients and provide exceptional pharmaceutical products.' },
    { title: 'Our Activities', description: 'We are importers and distributers of top quality Pharmaceutical brands. We engage with customers to gain best outcomes out of our product lines.' },
    { title: 'Our Clients', description: 'Our clients range from Fertility clinics such as Lister, Finney, Shape, Provita, Mount Camel to name a few. We also deal with pharmaceutical distributers such as Ernest Chemists, East Cantoments Pharmacy, Bedita, Arex MD Pharma ....' },
  ];

  return (
    <>
      <Header />
      <div className="container-main">
        {data.map((item, index) => (
          <div className="about-section" key={index}>
            <h2 className="title">{item.title}</h2>
            <p className="description">{item.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default About;
