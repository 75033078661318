import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import emptyCart from '../../assets/empty_cart.svg';
import Cart from './Cart';
import './mini_cart.css';
import MiniCart from './MiniCart';
import { base } from '../../util/apiRoutes';
import isSignedIn from '../../util/isSignedIn';
import { cartAdded, cartDelete } from '../../redux/reducers/cart';


const CartButton = ({noMini}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {cart} = useSelector((state) => state.cart);
  const [showMiniCart, setShowMiniCart] = useState(false);

  const handleShowMiniCart = () => {
    if(noMini){
      navigate(`/cart`);
    }else{
      setShowMiniCart((prev)=> !prev);
    }
  }

  const getCartTotalQuantity = () => {
    let count = 0;
    Object.values(cart).forEach((cartItem) => {
      count += cartItem.quantity;
    });
    return count;
  };

  const getCart = async() => {
    if (!isSignedIn()) return;
    try {
      const url = `${base}/mycart`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error fetching cart');
      }

      const data = await response.json();
      if (data.length > 0) {
        dispatch(cartDelete);
      }
      data.forEach(item => {
        const {product, quantity} = item;
        dispatch(cartAdded({ product, quantity}));
      });
    } catch (error) {
      toast.error(error.message || 'Failed to fetch cart');
    }
  }
  
  useEffect(() => {
    getCart();
  }, [])

  return (
    <>
      <button
        type="button"
        className="btn-colorless cartMenuBtn"
        onClick={handleShowMiniCart}
      >
        <p className="bullet cartQty">
          {getCartTotalQuantity()}
        </p>
        <img className="cartMenuBtn" src={emptyCart} alt="empty cart" />
      </button>
      {showMiniCart && (
        <MiniCart handleShowMiniCart={handleShowMiniCart}>
          <Cart page="mini" />
        </MiniCart>
      )} 
    </>
  );
};

export default CartButton;
