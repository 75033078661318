import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import './EditProduct.css';

function EditProduct({ product, onSave }) {
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const [categories, setCategories] = useState([]);
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('/categories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleFieldChange = (field, value) => {
    setEditedProduct((prevEditedProduct) => ({ ...prevEditedProduct, [field]: value }));
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setNewImage(imageFile);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('product[name]', editedProduct.name);
      formData.append('product[description]', editedProduct.description);
      formData.append('product[quantity]', editedProduct.quantity);
      formData.append('product[price]', editedProduct.price);

      if (newImage) {
        formData.append('product[image]', newImage);
      }

      // Append the new category ID if it has changed
      if (editedProduct.tag_id !== product.tag_id) {
        formData.append('product[tag_id]', editedProduct.tag_id);
      }

      const response = await fetch(`/products/${product.id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });

      if (response.ok) {
        const updatedProduct = await response.json();
        toast.success(`Updated ${updatedProduct.name}`);
        onSave(updatedProduct);
      } else {
        throw new Error(`Error updating ${product.name}`);
      }
    } catch (error) {
      toast.error(error || `Error updating ${product.name}`);
    }
  };

  return (
    <>
      <div className="edit-product-container">
        <h1>Edit Product</h1>
        <form onSubmit={handleSave} encType="multipart/form-data">
          <div className="field">
            <label htmlFor="product_name">Name</label>
            <input
              type="text"
              name="product[name]"
              id="product_name"
              value={editedProduct.name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
          </div>
          <div className="field">
            <label htmlFor="product_description">Description</label>
            <textarea
              name="product[description]"
              id="product_description"
              value={editedProduct.description}
              onChange={(e) => handleFieldChange('description', e.target.value)}
            ></textarea>
          </div>
          <div className="field">
            <label htmlFor="product_quantity">Quantity</label>
            <input
              type="number"
              name="product[quantity]"
              id="product_quantity"
              value={editedProduct.quantity}
              onChange={(e) => handleFieldChange('quantity', parseInt(e.target.value))}
            />
          </div>
          <div className="field">
            <label htmlFor="product_price">Price</label>
            <input
              type="number"
              name="product[price]"
              id="product_price"
              value={editedProduct.price}
              onChange={(e) => handleFieldChange('price', parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="product_category">Category</label>
            <select
              name="product[tag_id]"
              id="product_category"
              value={editedProduct.tag_id}
              onChange={(e) => handleFieldChange('tag_id', parseInt(e.target.value))}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <label htmlFor="product_image">Image</label>
            <input
              type="file"
              name="product[image]"
              id="product_image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div className="actions">
            <button type="submit">Save Changes</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditProduct;
