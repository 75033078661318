import React from 'react';
import { useSelector } from 'react-redux';

import './cart.css';
import CartContent from './CartContent';

const Cart = ({page}) => {
  const {cart} = useSelector((state) => state.cart);
  const minicart = page === 'mini'

  if (Object.keys(cart).length < 1) {
    return (
      <div
        className={`cart-is-empty ${!minicart && 'cart-empty-fullscreen'  }`}
      >
        <h3>
          No items in cart
        </h3>
      </div>
    );
  } else {
    return <CartContent page={page} />;
  }
};

export default Cart;
