import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './Sales.css';
import Header from '../Components/Header/Header';
import { base } from '../util/apiRoutes';
import isAdmin from '../util/isAdmin';

function Sales() {
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading ] = useState(true);

  const fetchSales = async() => {
    setIsLoading(true);
    try {
      let url = `${base}/sales`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSales(data);
      } else {
        throw new Error('Error fetching sales');
      }
    } catch (error) {
      toast.error(error || 'Error fetching sale');
    } finally {
        setIsLoading(false);
    }
  }

  useEffect(() => {
    if(!isAdmin) {
      toast.error('Not authorized');
      navigate(`/`);
      return
    }

    fetchSales();
  }, []);

  const completeSale = async(e, saleId) => {
    e.preventDefault();
    try {
      // Make the API call to Rails to add the product to the cart
      const response = await fetch('/admin/sales/complete', {
        method: 'PATCH',
        body: JSON.stringify({
          id: saleId,
          status: 'completed'
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      console.log(response);
      // Check if the response status is within the 2xx range (success range)
      if (response.ok) {
        console.log(response);
        toast.success('Sale completed');
        // window.location.reload();
      } else {
        throw new Error('No data found');
      }
    } catch (error) {
      toast.error(error || 'Error updating  sale');
    }
  };

  if (sales.length < 1) {
    return (
      <>
        <Header />
        <div className="no-purchases">
          {
            isLoading ?
              <p>Loading...</p>
            :
              <h3>No Sales</h3>
          }
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="sales-list">
        <h2>All Sales</h2>
        <ul>
          {sales.map(sale => (
            <li key={sale.id} className="sale-item">
              {
                sale.status !== 'completed' &&
                <button type="button" className="complete-sale-btn" onClick={(e) => completeSale(e,sale.id)}>Complete</button>
              }
              <div className="customer-details">
                <p>Customer Name: {JSON.parse(sale.customer_information).first_name} {JSON.parse(sale.customer_information).last_name}</p>
                <p>Customer Phone: {JSON.parse(sale.customer_information).phone}</p>
                <p>Email: {JSON.parse(sale.customer_information).email}</p>
                <p>Location: {sale.delivery_location && sale.delivery_location.name}</p>
                <p>Extra details: {JSON.parse(sale.customer_information).additional_info}</p>
              </div>
              <ul className="sale-items">
                {sale.sale_items.map(item => (
                  <li key={item.id} className="item">
                    <p>{item.product.name}</p>
                    <p>{item.quantity}X</p>
                    <p>Total: ₵{(item.price * item.quantity).toFixed(2)}</p>
                    <a href={`${base}/products/description/${item.product.id}`}>View</a>
                  </li>
                ))}
                <li className="item">
                  <p>Delivery</p>
                  <p>₵{sale.delivery_location ? sale.delivery_location.cost : 0}</p>
                </li>
              </ul>
              <div className="total-amount">
              <h4>
                Total Sale Amount: ₵{(() => {
                  let deliveryLocationCost = 0;
                  if (sale.delivery_location ) {
                    deliveryLocationCost += parseFloat(sale.delivery_location.cost ? sale.delivery_location.cost : 0);
                  }
                  const saleItemCost = sale.sale_items.reduce((total, item) => total + (item.price * item.quantity), 0);
                  const totalSaleAmount = (deliveryLocationCost + saleItemCost).toFixed(2);
                  return totalSaleAmount;
                })()}
              </h4>

              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Sales;
