import React from 'react';

const Item = ({icon, title, description}) => {

  return (
    <li className="delivery-service-banner-item">
      <div className='icon'>
        {icon}
      </div>
      <div className="details">
        <h6 className="title">
          {title}
        </h6>
        <p className="description">
          {description}
        </p>
      </div>
    </li>
  );
};

export default Item;
