import React from 'react';
import './Item.css';

const Item = ({ userImage, userName, date, description }) => {
  return (
    <div className="testimonial">
      <div className="pic">
        <img src={userImage} alt="User" />
      </div>
      <p className="description">{description}</p>
      <h3 className="title">{userName}</h3>
      <small className="post">- {date}</small>
    </div>
  );
};

export default Item;
