import React, {useEffect, useState} from 'react';
import CategoryLink from './CategoryLink';
import "./Nav.css"

const NavLinks = () => {
  const [categories, setCategories] = useState([])

  const getCategories = async () => {
    try {
      const response = await fetch(`/categories`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (!response.ok) {
        // Handle the error response if the server returns an error status
        throw new Error('Failed to fetch categories');
      }
  
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      // Handle any other errors that may occur during the fetch
      console.error('Error fetching categories:', error);
      // You can also set a state variable to indicate the error and display an error message to the user
    }
  };
  

  useEffect(() => {
    getCategories();
  }, []);

  const displayNavLinks = () => {
    return categories.map(category => (
      <CategoryLink
        key={category}
        category={category}
      />
    ));
  };

  const home = {name:'home'}

  return (
    <nav>
      {
        <ul className="navLinks d-flex">
          <CategoryLink
            key={home.name}
            category={home}
          />
          {displayNavLinks()}
        </ul>
      }
    </nav>
  );
};

export default NavLinks;
