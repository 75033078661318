import React, { useEffect, useState } from 'react';
import ProductsIndex from '../Components/PLP/ProductsIndex';
import HeaderAdmin from '../Components/Header/HeaderAdmin';
// import './AdminIndex.css';
import { base } from '../util/apiRoutes';

function AdminIndex() {
  const [pageNum, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('all'); // Default is 'all'
  const [tags, setTags] = useState([]);

  useEffect(() => {
    searchProducts();
    getTags();
  }, [pageNum]);

  const searchProducts = async () => {
    try {
      // const tag = category === 'all' ? '' : category; // Use empty string if 'all', otherwise use the selected category
      const response = await fetch(`${base}/products/find/${category}/${query || "is_empty"}/${pageNum}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setProducts(data);
      } else {
        console.error('Failed to fetch products:', response.statusText);
      }
    } catch (error) {
      console.error('Error while fetching products:', error);
    }
  };

  const getTags = async () => {
    try {
      const response = await fetch(`${base}/tags`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTags(data);
      } else {
        console.error('Failed to fetch tags:', response.statusText);
      }
    } catch (error) {
      console.error('Error while fetching tags:', error);
    }
  };

  const handleSearch = () => {
    setPage(1);
    searchProducts();
  };

  return (
    <>
      <HeaderAdmin />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search products..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="all">All</option>
          {tags.map((tag) => (
            <option key={tag.id} value={tag.name}>
              {tag.name}
            </option>
          ))}
        </select>
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="product-index-container">
        <ProductsIndex products={products} />
      </div>
    </>
  );
}

export default AdminIndex;
