import React from 'react';
import { TbTruckDelivery, TbChecklist } from 'react-icons/tb';
import { MdPayment } from 'react-icons/md';
import { PiHandCoins } from 'react-icons/pi';
import { FaHandsHelping } from 'react-icons/fa';

import './Delivery.css'
import Item from './Item';

const Delivery = () => {
  const list = [
    {icon: <TbTruckDelivery /> , title: 'Delivery', description: 'We deliver to you'},
    {icon: <MdPayment />, title: 'Safe Payment', description: '100% secure payment'},
    {icon: <PiHandCoins />, title: 'Shop With Confidence', description: 'If goods have problems'},
    {icon: <FaHandsHelping />, title: 'Help Center', description: 'Dedicated support'},
    {icon: <TbChecklist />, title: 'Friendly Services', description: '30 day satisfaction guarantee'},
  ]

  return (
    <ul className='delivery-service-banner'>
      {
        list.map((i) => (
          <Item 
            icon={i.icon}
            title={i.title}
            description={i.description}
          />
        )
      )}
    </ul>
  );
};

export default Delivery;
