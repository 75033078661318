import React from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import addToCartIcon from '../../assets/in_cart.svg';
import { useSelector, useDispatch } from 'react-redux';
import {cartAdded} from '../../redux/reducers/cart'
import NoImage from '../../assets/firstAid.jpg';
import { base } from '../../util/apiRoutes';
import isSignedIn from '../../util/isSignedIn';
import ProductDescription from '../PDP/ProductDescription';


const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const {cart} = useSelector((state) => state.cart);

  const addToCart = async () => {
    if (isSignedIn()) {
      try {
        // Make the API call to Rails to add the product to the cart
        const response = await fetch('/carts', {
          method: 'POST',
          body: JSON.stringify({
            product_id: product.id,
            quantity: 1,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
        });
  
        // Check if the response status is within the 2xx range (success range)
        if (response.ok) {
          dispatch(cartAdded({ product, quantity: 1 }));
          toast.success(`Added ${product.name} to cart`);
        } else {
          throw new Error('Failed to create doctor');
        }
      } catch (error) {
        toast.error(error.message || 'Failed to add to cart');
      }
    } else {
      dispatch(cartAdded({ product, quantity: 1 }));
      toast.success(`Added ${product.name} to cart`);
    }
  };


  

  const displayProduct = () => {
    const { name, price, image, quantity, id, description } = product;
    const inStock = quantity > 0;
    let isInCart = cart[id] || false;

    return (
      <div 
        className={`product-card ${!inStock && 'fade-content'}`}
      >
        <NavLink to={`${base}/products/description/${id}`}>
          <div className='show-hovered-description'>
            <ProductDescription text={description} />
          </div>
        </NavLink>
        {quantity < 1 && (
          <div className="product-outOfStock">
            <p>out of stock</p>
          </div>
        )}

        {!isInCart && inStock && (
          <div className="product-inCart">
            <button className="btn-colorless" onClick={addToCart}>
              <img src={addToCartIcon} alt="add to cart" loading="lazy" />
            </button>
          </div>
        )}

        <NavLink to={`${base}/products/description/${id}`}>
          <img src={image || NoImage} alt={name} className="product-image"/>
          <p className="product-name">
            <span>{name}</span>
          </p>
          <p className="product-price d-flex">
            <span>₵</span>
            <span>{price}</span>
          </p>
        </NavLink>
      </div>
    );
  };

  return (
    <>
      {displayProduct()}
    </>
  );
};

export default ProductCard;
