import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header/Header';
import ProductsIndex from '../Components/PLP/ProductsIndex';
import './Results.css'
import { base } from '../util/apiRoutes';

function Results() {
  const { query, category, page } = useParams();
  const [pageNum, setPage] = useState(+page);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const searchProducts = async () => {
    if (!category && !query) return;
    setIsLoading(true);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    try {
      let url;
      if (category) {
        url = `${base}/products/category/${category}/${pageNum}/`;
      } else if (query) {
        url = `${base}/products/search/${query}/${pageNum}/`;
      }else {
        url = `${base}/products/index`;
      }
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      if (response.ok) {
        // Handle non-2xx HTTP response status (e.g., 404, 500)
        const data = await response.json();
        setProducts(data);
      }else {
        throw new Error('Error fetching products');
      }
    } catch (error) {
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    searchProducts();
  }, [pageNum]);

  useEffect(() => {
    setPage(1);
    searchProducts();
  }, [category, query]);

  const howMany = () => {
    if (!isLoading && products.length < 1) {
      return 'The are no products for this search'
    }
    else if (isLoading) {
      return 'Loading...'
    }
    return `Showing results for ${query || category || 'All'}`
  }

  const handlePageChange = (num) => {
    if (num < 0 && pageNum + num > 0) {
      setPage((prevState) => prevState + num);
    }

    else if (pageNum + num > 0 && products.length > 5) {
      setPage((prevState) => prevState + num);
    }
  };

  const showPagNav = () => {
    if (pageNum > 1) return true;
    if (products.length > 5) return true
    return false;
  }
 
  return (
    <>
      <Header />
      <div className='container-main'>
        <div className="results-banner">
          <div className="results-sort">
            <p>
              {howMany()}
            </p>
          </div>
        </div>
        <hr/>
        <div className="product-index-container">
          {
            Array.isArray(products) &&
            <ProductsIndex products={products}/>
          }
        </div>
        {
          showPagNav() &&
          <div className='pag-container'>
            <button name="prev-button" type="button" onClick={() => handlePageChange(-1)}>
              Prev
            </button>
            <p>
              {pageNum}
            </p>
            <button type="button" onClick={() => handlePageChange(1)}>
              Next
            </button>
          </div>
        }
      </div>
    </>
  );
}

export default Results;
