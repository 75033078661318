import React, { useState } from 'react';
import { toast } from 'react-toastify';

import './Footer.css'
import Image from '../../assets/logo.png'
import { BsArrowRightShort, BsTelephoneFill, BsFacebook, BsInstagram } from 'react-icons/bs';
import { GrMapLocation } from 'react-icons/gr';
// import { FcPhoneAndroid } from 'react-icons/fc';
import { base } from '../../util/apiRoutes';
import { handleRedirect } from '../../util/functions';

function Footer() {
  const [details, setDetails] = useState('');

  const sendInquiry = async() => {
    try {
      let url = `${base}/inquiry`;
  
      const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
          details: details
      }),
      headers: {
          Accept: 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      });
  
      if (response.ok) {
      // Handle non-2xx HTTP response status (e.g., 404, 500)
      toast.success(`Inquiry submitted`);
      }else {
      throw new Error('Error sending products');
      }
    } catch (error) {
        toast.error('Inquiry was not sent, please try again');
    }
  }

  return (
    <div className="footer">
      <div className="newsletter">
        <h2>
          Inquiry
        </h2>
        <p>
          Join 10000+ Subscribers and get a amazing <br/>
          discounts and offers.
        </p>
        <div className="subscribe-form">
          <input
            type="details"
            placeholder="Enter your details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
          <button className="submit-btn" onClick={sendInquiry}>
            <p className="subscribe-button-text">
              Submit
            </p>
            <p className="icon-container-subscribe">
              <BsArrowRightShort />
            </p>
          </button>
        </div>
      </div>

      <div className="contact">
        <div className="contact-item">
          <div className='icon-container-footer'>
            <GrMapLocation />
          </div>
          <div className="text">
            <h4>Address</h4>
            <p>Community 19, Lashibi</p>
          </div>
        </div>
        <div className="contact-item">
          <div className='icon-container-footer'>
            <BsTelephoneFill />
          </div>
          <div className="text">
            <h4>CALL US</h4>
            <p className="phone-num">(233)-24-434-4223</p>
            <p className="phone-num">(233)-24-899-1647</p>
            <p>info@capachemists.com.gh</p>
          </div>
        </div>
        {/* <div className="contact-item">
          <div className='icon-container-footer'>
            <FcPhoneAndroid />
          </div>
          <div className="text">
            <h4>App coming soon!</h4>
          </div>
        </div> */}
      </div>
      <hr/>
      <div className="other-info">
        <div className='other-item'>
          <div className="logo-container">
            <img src={Image} alt="logo" />
          </div>
          <br/>
          <p>
            We are importers and distributers of top 
            quality pharmaceutical brands and non-pharmaceutical products
          </p>
        </div>
        <div className='other-item-multiple'>
          <div className='section'>
            <h4>Info</h4>
            <ul>
              <li>
                <a
                  href={`${base}/about-us`}
                  onClick={(e)=> handleRedirect(e, `${base}/about-us`)}
                >
                    About Us
                </a>
              </li>
              <li>
                <a
                  href={`${base}/contact-us`}
                  onClick={(e)=> handleRedirect(e, `${base}/contact-us`)}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='other-item footer-social'>
          <h4>Socials</h4>
          <ul>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61550597452894&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                <BsFacebook />
              </a>
            </li>         
            <li>
              <a href="https://instagram.com/capachemists?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D" target="_blank" rel="noopener noreferrer">
                <BsInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright © {new Date().getFullYear()} Capachemist. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
