import React, { useEffect } from 'react';
import Carousel from '../Components/Carousel/Carousel';
import Header from '../Components/Header/Header';
// import EssentialCategories from '../Components/EssentialCatategories/EssentialCategories';
import Lifestyle from '../Components/Lifestyle/Lifestyle';
import Delivery from '../Components/Delivery/Delivery';
import FeaturedBrands from '../Components/FeaturedBrands/FeaturedBrands';
// import PopularCategories from '../Components/PopularCategories/PopularCategories';
// import FromBlog from '../Components/FromBlog/FromBlog';
import ServiceStats from '../Components/ServiceStats/ServiceStats';
import Reviews from '../Components/Reviews/Reviews';

function Home() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <Header />
      <div className="container-main">
        <Carousel />
        <div className='container-margin'>
          {/* <EssentialCategories /> */}
          <Delivery />
          <div className="empty-divider-banner"></div>
          <FeaturedBrands />
          <Lifestyle />
          {/* <PopularCategories /> */}
          {/* <FromBlog /> */}
          <ServiceStats />
          <Reviews />
        </div>
      </div>
    </>
  );
}

export default Home;
